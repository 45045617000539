<template>
  <div class="wp">
    <div class="wp-forbidden">
      <img src="static/imgs/forbidden.png" width="200px"/>
      <h1>很抱歉！您暂时无访问该页面的权限 </h1>
      <div class="desc">请联系管理员开通权限</div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'Forbidden',
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.wp{
  display: flex;
  background-color: #f0f0f0;
  height: 100%;
  margin-left: 4px;
  margin-top: 2px;

  .wp-forbidden{
    width: 300px;
    margin: auto;
    text-align: center;

    h1{
      font-size: 18px;
      text-align: center;
      color: #81B3F8;
    }
    .desc{
      font-size: 16px;
      text-align: center;
      color: #aaa;
    }
  }
}

</style>

